@import '../../assets/style/variables.module.scss';

.cart {
  width: 100vw;
  min-height: 100vh;
  padding: 10.5vh 13vh 4vh 8vh;
  box-sizing: border-box;
  background: #f4f3f4;
  position: relative;

  .cartIcon {
    position: absolute;
    right: 35px;
    bottom: 50px;
    height: 90px;
  }

  .emptyCartImage {
    width: 80%;
    margin: auto;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 85px;
    margin-top: 60px;

    .shell {
      height: 25vh;
    }

    .cartSide {
      width: 60%;
    }
    .emptyCart {
      color: #70777e;
      font-size: 1.8vh;
      margin-bottom: 5vh;
      letter-spacing: 0.1vh;
    }

    .itemWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .item {
        display: flex;
        gap: 45px;
        align-items: center;

        .countwrapper {
          display: flex;
          align-items: center;
          position: relative;

          .downButton {
            transform: rotate(90deg);
          }
          .upButton {
            transform: rotate(-90deg);
          }

          .maxItemDesktop {
            position: absolute;
            left: 0;
            bottom: -60px;
            font-size: 12px;
            color: $--main-red;
          }

          .deleteButton {
            margin-left: 2vh;
          }

          .count {
            border: 1px solid $--main-red;
            font-size: 1.6vh;
            padding: 0.4vh;
            color: $--main-red;
            font-weight: 700;
            letter-spacing: 0.1vh;
          }

          button {
            height: 42px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 100%;
              width: 100%;
              border-radius: unset;
              object-fit: contain;
              object-position: unset;
            }
          }
        }

        .mainImage {
          height: 150px;
          width: 150px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }

        .info {
          position: relative;

          &::after {
            content: '';
            height: 130%;
            width: 200px;
            position: absolute;
            right: 0;
            top: -10px;
            z-index: 0;
            background: #f4f3f4;
          }

          &::before {
            content: '';
            height: 100%;
            width: 200px;
            border: 1px dashed $--main-red;
            position: absolute;
            right: -25px;
            z-index: 0;
            top: 0;
          }

          h3 {
            font-size: 17px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 0.08em;
            max-width: 37vh;
            position: relative;
            z-index: 1;
          }

          h4 {
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0.08em;
            margin-top: 20px;
            position: relative;
            z-index: 1;
          }

          h5 {
            letter-spacing: 0.08em;
            font-size: 17px;
            font-weight: 500;
            position: relative;
            z-index: 1;
          }

          h6 {
            letter-spacing: 0.08em;
            font-size: 17px;
            font-weight: 700;
            margin-top: 20px;
            position: relative;
            z-index: 1;

            span {
              font-weight: 500;
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 35px;
      .seperator {
        width: 610px;
      }

      .payment {
        display: flex;
        align-items: center;
        gap: 3vh;
        margin-top: 4vh;

        .total {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1vh;

          h4 {
            font-weight: 700;
            font-size: 2.3vh;
            color: $--main-red;
          }
        }

        .column {
          width: 25%;

          .billWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: flex-start;

            .billingRow {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              h6 {
                font-size: 14px;
                font-weight: 500;
              }

              .active {
                .square {
                  background: $--main-red;
                }
              }

              .billButton {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                .square {
                  width: 30px;
                  height: 30px;
                  border: 2px solid $--main-red;
                }

                img {
                  width: 50px;
                  height: 50px;
                  transform: rotate(90deg);
                }
              }
            }
          }

          h2 {
            font-size: 1.8vh;
            font-weight: 500;
            height: 3vh;
            margin-bottom: 3vh;
            word-break: keep-all;
            width: 200%;
          }
          form {
            span {
              font-size: 1.5vh;
              margin-top: 1vh;
              color: rgb(254, 93, 93);
            }

            .fill {
              input,
              select,
              textarea {
                border: 1px solid #70777e;
              }
            }

            label {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &:not(:last-child) {
                margin-bottom: 2vh;
              }

              h5 {
                font-weight: 500;
                font-size: 1.8vh;
                margin-bottom: 0.4vh;
              }

              input {
                height: 2.5vh;
                background: transparent;
                border: 1px solid $--main-red;
                padding-left: 1vh;
                padding-top: 0.5vh;
                padding-bottom: 0.5vh;
                width: 100%;
              }

              select {
                height: 3.7vh;
                width: 105%;
                background: transparent;
                border: 1px solid $--main-red;
                padding-left: 1vh;
              }

              textarea {
                background: transparent;
                border: 1px solid $--main-red;
                resize: none;
                height: 6vh;
                padding-left: 1vh;
                padding-top: 0.5vh;
                padding-bottom: 0.5vh;
                width: 100%;
              }
            }
          }
        }

        .cartFormSeperator {
          height: 40vh;
        }
      }

      .submitWrapper {
        position: relative;
        .ghostButton {
          width: 100%;
          height: 100%;
          background: red;
          position: absolute;
          pointer-events: visible;
        }
      }

      .buyButton {
        border: 1px solid $--main-red;
        padding: 10px;
        font-size: 17px;
        letter-spacing: 0.08em;
        font-weight: 400;
        margin-bottom: 4vh;
        color: $--main-red;
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .cart {
    padding: 30px 10px;
    min-height: 100vh;
    height: unset;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cartIcon {
      display: none;
    }
    .emptyCartWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .content {
      flex-direction: column;
      gap: 30px;
      width: 88%;
      .shell {
        height: 100px;
        display: none;
      }

      .cartSide {
        width: 100%;
        .itemWrapper {
          .item {
            gap: 20px;
            .info {
              display: none;
            }

            .leftItem {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;

              .mainImage {
                width: 120px;
                height: 120px;
              }

              .countwrapper,
              .mainImage {
                z-index: 2;
              }

              .countwrapper {
                margin-top: 20px;

                .count {
                  line-height: 10px;
                }
              }

              &::after {
                content: '';
                height: 80%;
                width: 110%;
                position: absolute;
                left: 0;
                top: -30px;
                z-index: 0;
                background: #f4f3f4;
              }

              &::before {
                content: '';
                height: 80%;
                width: 100%;
                border: 1px dashed $--main-red;
                position: absolute;
                left: 0;
                bottom: 50px;
                z-index: 0;
              }
            }

            .mobileInfo {
              display: flex;
              flex-direction: column;

              .maxItem {
                font-size: 10px !important;
                color: $--main-red;
                margin-top: 10px;
              }
              h3 {
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                margin-bottom: 10px;
              }
              h4 {
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                margin-bottom: 20px;
              }

              h6 {
                font-size: 16px;
                font-weight: 500;

                span {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .bottom {
          align-items: center;
          .seperator {
            height: auto;
            width: 100%;
          }
          .payment {
            width: 100%;
            padding-bottom: 100px;

            .cartFormSeperator {
              transform: rotate(180deg) !important;
              height: 300px;
            }

            .total {
              position: absolute;
              bottom: 0px;
            }

            .cartFormSeperator {
              transform: rotate(90deg);
              margin: -150px 0;
            }

            .column {
              width: 100%;

              .billWrapper {
                .billingRow {
                  .square {
                    width: 20px;
                    height: 20px;
                  }
                  h6 {
                    font-size: 12px;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
