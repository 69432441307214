@import '../../assets/style/variables.module.scss';

.callback {
  width: 100vw;
  min-height: 100vh;
  padding: 10.5vh 13vh 4vh 8vh;
  box-sizing: border-box;
  background: #f4f3f4;
  position: relative;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 8vh;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 61%;

    h1 {
      font-size: 1.8vh;
      margin-bottom: 8vh;
      color: $--main-red;
      font-weight: 700;
      line-height: 2.4vh;
      letter-spacing: 0.1vh;
    }

    img {
      height: 40vh;
    }
  }

  .error {
    .wrapper {
      width: 100%;
      align-items: center;
      .mainImage {
        height: 26vh;
        margin-bottom: 8vh;
      }
      h1 {
        color: #70777e;
        margin-bottom: 8vh;
      }
      .seperator {
        height: 2vh;
        margin-bottom: 8vh;
      }

      a {
        font-size: 1.8vh;
        margin-bottom: 8vh;
        color: $--main-red;
        font-weight: 700;
        line-height: 2.4vh;
        letter-spacing: 0.1vh;
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .callback {
    padding: 50px 10px;
    height: unset;
    min-height: unset;
    min-height: 80vh;

    .content {
      flex-direction: column;
      align-items: center;

      .wrapper {
        width: 90%;
        align-items: center;
        justify-content: center;

        .seperator {
          width: 100%;
        }
        img {
          height: 200px;
        }
        .mainImage {
          height: 200px;
        }
      }
    }
  }
}
