@import '../../assets/style/variables.module.scss';

.home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding: 10.5vh 13vh 4vh 8vh;
  box-sizing: border-box;
  background: #f4f3f4;
  position: relative;
  align-items: center;

  .istakoz {
    position: absolute;
    height: 42.8vh;
    right: 14vw;
  }

  .languageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 30px;
    position: relative;
    .languageButton {
      color: white;
      background: url('../../assets/img/about/object-background.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 100px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 10px;
      letter-spacing: 0.08em;
      cursor: pointer;
      position: absolute;
      right: 0;
      h6 {
        font-weight: 500;
      }

      select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .top {
      position: relative;

      .languageButton {
        position: absolute;
        right: 0;
        top: 15vh;
        color: white;
        background: url('../../assets/img/about/object-background.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 18vh;
        height: 8vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.6vh;
        letter-spacing: 0.08em;
        cursor: pointer;

        select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }

    .logo {
      height: 45vh;
    }
    .book {
      height: 25vh;
    }

    .castle {
      height: 29.2vh;
    }

    .buttonWrapper {
      display: flex;
      gap: 40px;

      .shop {
        font-size: 2.7vh;
        color: $--main-red;
        border: 2px solid $--main-red;
        padding: 2.7vh;
        box-sizing: border-box;
        text-align: left;
        font-weight: 500;
        transition: all 0.4s ease;
        position: relative;
        letter-spacing: 0.2vh;
        line-height: 3.1vh;

        &:hover {
          .circle {
            display: flex;
          }
        }

        .circle {
          width: 25px;
          height: 25px;
          background: $--main-red;
          border-radius: 50%;
          position: absolute;
          display: none;

          &:nth-child(2) {
            right: 10px;
            top: -12.5px;
          }

          &:nth-child(3) {
            right: 40%;
            bottom: -12.5px;
          }

          &:nth-child(4) {
            left: 20%;
            bottom: -50px;
          }

          &:nth-child(5) {
            left: -12.5px;
            top: -12.5px;
          }
        }
      }

      .about {
        font-size: 2.7vh;
        color: $--main-red;
        border: 2px solid $--main-red;
        padding: 2.7vh;
        box-sizing: border-box;
        text-align: left;
        font-weight: 500;
        transition: all 0.4s ease;
        position: relative;
        letter-spacing: 0.2vh;
        line-height: 3.1vh;

        &:hover {
          .circle {
            display: flex;
          }
        }

        .circle {
          width: 25px;
          height: 25px;
          background: $--main-red;
          border-radius: 50%;
          position: absolute;
          display: none;

          &:nth-child(2) {
            left: 10px;
            top: -12.5px;
          }

          &:nth-child(3) {
            right: 25%;
            bottom: -12.5px;
          }

          &:nth-child(4) {
            right: 20%;
            top: -50px;
          }

          &:nth-child(5) {
            left: -12.5px;
            bottom: -12.5px;
          }
          &:nth-child(6) {
            top: 10px;
            right: 10px;
          }
        }
      }
    }

    .top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .bottom {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      p {
        color: $--main-red;
        font-size: 18px;
        letter-spacing: 0.09em;
        line-height: 25px;
        font-weight: 200;
        transition: all 0.5s ease;
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .home {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: unset;
    min-height: 100vh;
    padding: 50px 0;
    box-sizing: border-box;
    .mobile {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 85vh;
      gap: 10px;

      .shop,
      .about {
        .circle {
          height: 10px !important;
          width: 10px !important;

          &:nth-child(2) {
            right: 20px !important;
            top: -6.5px !important;
          }

          &:nth-child(3) {
            right: 40% !important;
            bottom: -6.5px !important;
          }

          &:nth-child(4) {
            left: 20% !important;
            bottom: -15px !important;
          }

          &:nth-child(5) {
            left: -12.5px !important;
            top: -12.5px !important;
          }
        }
      }

      .logo {
        width: 70%;
      }

      .book {
        width: 60%;
      }

      .istakoz {
        width: 60%;
        height: auto;
        position: static;
        margin-top: 20px;
      }

      .castle {
        width: 30%;
      }

      .seperator {
        width: 90%;
      }

      .shop {
        font-size: 12px;
        color: $--main-red;
        border: 2px solid $--main-red;
        padding: 10px;
        box-sizing: border-box;
        text-align: left;
        font-weight: 500;
        transition: all 0.4s ease;
        position: relative;
        letter-spacing: 0.05em;
        line-height: 16px;
        text-align: center;
        margin-bottom: 25px;

        &:hover {
          .circle {
            display: flex;
          }
        }

        .circle {
          width: 25px;
          height: 25px;
          background: $--main-red;
          border-radius: 50%;
          position: absolute;
          display: none;

          &:nth-child(2) {
            right: 10px;
            top: -12.5px;
          }

          &:nth-child(3) {
            right: 40%;
            bottom: -12.5px;
          }

          &:nth-child(4) {
            left: 20%;
            bottom: -50px;
          }

          &:nth-child(5) {
            left: -12.5px;
            top: -12.5px;
          }
        }
      }

      .about {
        font-size: 12px;
        color: $--main-red;
        border: 2px solid $--main-red;
        padding: 10px;
        box-sizing: border-box;
        text-align: left;
        font-weight: 500;
        transition: all 0.4s ease;
        position: relative;
        letter-spacing: 0.05em;
        line-height: 16px;
        text-align: center;

        &:hover {
          .circle {
            display: flex;
          }
        }

        .circle {
          width: 25px;
          height: 25px;
          background: $--main-red;
          border-radius: 50%;
          position: absolute;
          display: none;

          &:nth-child(2) {
            left: 10px;
            top: -12.5px;
          }

          &:nth-child(3) {
            right: 25%;
            bottom: -12.5px;
          }

          &:nth-child(4) {
            right: 20%;
            top: -50px;
          }

          &:nth-child(5) {
            left: -12.5px;
            bottom: -12.5px;
          }
          &:nth-child(6) {
            top: 10px;
            right: 10px;
          }
        }
      }
    }
  }
}
