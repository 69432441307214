@import '../../assets/style/variables.module.scss';

.objectShop {
  width: 100vw;
  height: 100vh;
  padding: 10.5vh 13vh 4vh 8vh;
  box-sizing: border-box;
  background: #f4f3f4;
  position: relative;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55vh;
    margin-top: 6vh;

    .left {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding-right: 60px;

      .sandCastle {
        height: 17vh;
        margin-top: 7vh;
      }

      .etiket {
        color: white;
        background: url('../../assets/img/about/object-background.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 22vh;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.7vh;
        letter-spacing: 0.08em;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      width: 70%;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      .itemWrapper {
        display: flex;
        gap: 7vh;
        margin-bottom: 7vh;

        .item {
          width: 100%;
          height: 45vh;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            .circle {
              display: flex;
            }
          }

          .circle {
            position: absolute;
            width: 27vh;
            height: 27vh;
            background: $--main-red;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 1.4vh;
            display: none;

            .sizeSelect {
              position: relative;
              display: flex;
              align-items: center;
              gap: 5px;
              span {
                border: 2px solid white;
                color: white;
                padding: 4px 7px;
                font-weight: 700;
                font-size: 12px;
              }

              img {
                width: 9px;
              }
              select {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
              }
            }

            h3,
            h5,
            button {
              letter-spacing: 0.08em;
              color: white;
              font-weight: 18px;
            }

            h3 {
              text-align: center;
              white-space: pre-line;
              line-height: 19px;
              font-style: italic;
              font-weight: 400;
              max-width: 80%;
              font-size: 14px;
            }

            h5 {
              font-weight: 500;
              font-size: 14px;
            }

            button {
              font-weight: 500;
              font-size: 14px;
              font-style: italic;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      img {
        height: 25vh;
        transform: rotate(-90deg);
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .objectShop {
    padding: 30px 10px;
    min-height: 100vh;
    height: unset;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      flex-direction: column;

      .left {
        width: 100%;
        padding: 0;

        .sandCastle {
          width: 60%;
          margin-top: 20px;
          display: none;
        }
      }

      .right {
        width: 100%;
        padding: 0;

        img {
          width: 60%;
          display: none;
        }
      }

      .center {
        .itemWrapper {
          flex-direction: column;
          gap: 20px;

          .item {
            h3,
            h5,
            button {
              font-size: 10px !important;
            }

            h3 {
              line-height: 17px !important;
            }

            .sizeSelect {
              span {
                font-size: 10px !important;
                border: 1px solid white !important;
              }
            }
          }
        }
      }
    }
  }
}
