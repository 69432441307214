@import "../../assets/style/variables.module.scss";

.about {
  width: 100vw;
  min-height: 100vh;
  padding: 35px;
  box-sizing: border-box;
  background: #f4f3f4;
  position: relative;
  padding: 10.5vh 13vh 4vh 8vh;

  .content {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 18vh;
    margin-top: 6.5vh;

    .left,
    .right {
      width: 50%;
    }

    .left {
      h1 {
        font-size: 2.4vh;
        font-weight: 500;
        letter-spacing: 0.08em;

        span {
          color: $--main-red;
        }
      }

      p {
        font-size: 2.1vh;
        font-weight: 500;
        line-height: 2.45vh;
        margin-top: 2.9vh;
      }

      h2 {
        font-size: 2.2vh;
        font-weight: 500;
        letter-spacing: 0.08em;
        margin-top: 2.9vh;

        span {
          color: $--main-red;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 2.4vh;
        margin-top: 2.9vh;
        li {
          h4 {
            font-size: 1.8vh;
            font-weight: 400;
            letter-spacing: 0.08em;
            color: $--main-red;
            margin-bottom: 1vh;
          }

          h5,
          span {
            font-size: 1.8vh;
            font-weight: 400;
            letter-spacing: 0.08em;
          }

          h5 {
            color: $--main-red;
          }
        }
      }
    }

    .right {
      .soundWrapper {
        display: flex;
        align-items: center;
        margin-top: 2.9vh;

        p {
          margin-top: unset;
        }

        .sound {
          color: white;
          background: url("../../assets/img/about/sound-background.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          width: 22vh;
          height: 10vh;
          min-width: 22vh;

          min-height: 10vh;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 1.7vh;
          letter-spacing: 0.08em;
          margin-right: 20px;
        }
      }

      h1 {
        font-size: 2.4vh;
        font-weight: 500;
        letter-spacing: 0.08em;
      }

      p {
        font-size: 2.1vh;
        font-weight: 500;
        line-height: 2.45vh;
        margin-top: 2.9vh;
      }

      a {
        color: $--main-red;
      }

      h5 {
        font-size: 2.2vh;
        font-weight: 500;
        letter-spacing: 0.08em;
        margin-top: 2.9vh;

        span {
          color: $--main-red;
        }
      }

      .object {
        color: white;
        background: url("../../assets/img/about/object-background.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 22vh;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.7vh;
        letter-spacing: 0.08em;
        margin-top: 7vh;
      }
    }

    .castle {
      position: absolute;
      top: 0;
      right: 0;
      height: 12vh;
    }
  }
}

@media only screen and (max-width: 938px) {
  .about {
    padding: 30px 10px;
    min-height: 100vh;
    height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      flex-direction: column;
      padding: 0 10px;
      box-sizing: border-box;
      gap: 50px;
      width: 90%;

      .castle {
        display: none;
      }

      .left,
      .right {
        width: 100%;

        .object {
          margin: 0;
          margin-top: 40px;
        }
      }
    }
  }
}
