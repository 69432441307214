@import '../../assets/style/variables.module.scss';

.shopDetail {
  width: 100vw;
  min-height: 100vh;
  padding: 10.5vh 13vh 4vh 8vh;
  box-sizing: border-box;
  background: #f4f3f4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .content {
    width: 100%;
    display: flex;
    gap: 6vh;
    padding-top: 6.5vh;
    box-sizing: border-box;

    .nextItem {
      position: absolute;
      z-index: 1;
      height: 17vh;
      width: 7vh;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: -17vh;
      right: 2vh;
    }

    .imageWrapper {
      width: 68%;
      height: 67vh;
      position: relative;

      .slider {
        height: 100%;
        width: 100%;

        & > div > div {
          display: flex;
        }
      }

      .sliderButton {
        position: absolute;
        bottom: 20px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 10px;

        button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #f4f3f4;
        }

        .active {
          background: $--main-red;
        }
      }

      .sliderCard {
        width: 100%;
        height: 67vh;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .info {
      color: #00000a;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      width: 32%;
      .infoTop {
        display: flex;
        flex-direction: column;
      }
      h1 {
        font-size: 22px;
        font-style: italic;
        letter-spacing: 0.08em;
        font-weight: 500;
      }
      h2 {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.08em;
        margin-top: 11px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 16px;
        margin-top: 15px;
      }

      .descriptionInner {
        font-weight: 400;
        font-size: 1.2vh;
        letter-spacing: 0.08em;
        line-height: 1.7vh;
        margin-top: 15px;

        p {
          font-weight: 400;
          font-size: 11px;
          letter-spacing: 0.08em;
          line-height: 16px;
          margin: unset;
        }
      }

      h5 {
        font-size: 18.6px;
        font-weight: 700;
        margin-top: 22px;
        letter-spacing: 0.08em;

        span {
          font-weight: 500;
        }
      }

      .cartWrapper {
        width: 100%;
        display: flex;
        gap: 3vh;
        margin-top: 20px;
        position: relative;

        h6 {
          position: absolute;
          top: -9vh;
          font-size: 1.3vh;
          color: $--main-red;
          font-weight: 700;
          max-width: 27vh;
          line-height: 1.7vh;
        }

        button {
          background: $--main-red;
          border-radius: 50%;
          min-width: 12vh;
          min-height: 12vh;
          width: 90px;
          height: 90px;
          font-size: 14.6px;
          color: white;
          font-weight: normal;
          font-style: italic;
          letter-spacing: 0.08em;
        }

        img {
          height: 12vh;
          width: 70%;
        }
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .shopDetail {
    padding: 30px 10px;
    min-height: 100vh;
    height: unset;
    align-items: center;
    display: flex;
    flex-direction: column;

    .content {
      flex-direction: column;
      padding-top: 20px;
      width: 85%;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .nextItem {
        display: none;
      }

      .nextItemMobile {
        position: absolute;
        z-index: 10;
        height: 6vh;
        width: 7vh;
        margin: auto;
        position: absolute;
        bottom: -70px;
        right: 0;
        display: flex;
        align-items: center;
        flex-direction: column;

        span {
          font-size: 10px;
          text-align: center;
          color: $--main-red;
        }
      }

      .imageWrapper {
        .sliderButton {
          button {
            width: 12px;
            height: 12px;
          }
        }
      }

      .info {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .infoTop {
          h1 {
            font-size: 20px;
          }
          h2 {
            font-size: 20px;
            margin-top: 6px;
          }

          p {
            font-size: 12px;
            line-height: 16px;
          }

          .descriptionInner {
            font-size: 12px;
            line-height: 16px;
            margin-top: 16px;
          }
        }

        .cartWrapper {
          flex-direction: column;
          align-items: center;

          button {
            margin-bottom: 70px;
          }
          img {
            height: 50px !important;
            width: 200px;
            position: absolute;
            bottom: 0;
            transform: translate(15px);
          }
        }
      }

      .imageWrapper {
        width: 100%;
        height: 250px;

        .sliderCard {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}
