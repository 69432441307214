@import '../../assets/style/variables.module.scss';

.textPage {
  width: 100vw;
  padding: 10.5vh 13vh 4vh 8vh;
  box-sizing: border-box;
  background: #f4f3f4;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    width: 100%;
    display: flex;
    gap: 6vh;
    padding-top: 8vh;
    box-sizing: border-box;
    min-height: 100vh;

    .textWrapper {
      width: 82vw;
      h1 {
        color: $--main-red;
        margin-bottom: 2vh;
        letter-spacing: 0.2vh;
        font-size: 2.5vh;
      }
      .text {
        font-size: 1.8vh;
        width: 50vw;
        line-height: 2.7vh;
      }
    }

    .mainImage {
      height: 60vh;
      width: 40vw;
      object-fit: contain;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 938px) {
  .textPage {
    padding: 30px 10px;
    min-height: 100vh;
    height: unset;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
      flex-direction: column;
      align-items: center;

      .textWrapper {
        width: 85%;
        .text {
          width: 100%;
        }
      }

      .mainImage {
        height: 150px;
        display: none;
      }
    }
  }
}
