@import '../../assets/style/variables.module.scss';

.shop {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f4f3f4;
  padding: 10.5vh 13vh 4vh 8vh;

  .shopList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6vh;
    margin-top: 6.5vh;

    .shopItem {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30vh;

      &:hover {
        img {
          display: none;
        }
        .infoCircle {
          display: flex;
        }
      }

      .infoCircle {
        display: none;
        flex-direction: column;
        background: $--main-red;
        border-radius: 50%;
        height: 26vh;
        width: 26vh;
        align-items: center;
        justify-content: center;
        color: white;

        h5,
        h6,
        span {
          text-align: center;
          font-size: 2vh;
        }

        h5,
        h6 {
          font-weight: 400;
          font-style: italic;
        }

        h6 {
          margin-top: 6px;
        }

        span {
          margin-top: 3.6vh;
          font-weight: 300;
          font-style: italic;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .shop {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .arrow {
      width: 70px;
      height: 80px;
      position: sticky;
      bottom: 20px;
      transition: all 0.5s ease;
    }

    .shopList {
      grid-template-columns: 1fr;
      gap: 20px;
      width: 85%;

      .shopItem {
        position: relative;
        .eyeIcon {
          position: absolute;
          width: 60px;
          height: 60px;
          right: -30px;
        }
      }
    }
  }
}
