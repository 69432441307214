@import '../../assets/style/variables.module.scss';

$--footer-gray: #70777e;

.footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f3f4;
  color: $--footer-gray;

  .wrapper {
    border: 2px dashed $--footer-gray;
    width: 92%;
    display: flex;
    padding: 3vh;
    box-sizing: border-box;
    gap: 2vh;
    justify-content: space-between;
  }

  .center {
    display: flex;
    gap: 2vh;
    justify-content: space-between;
    width: 80%;

    .seperator {
      height: 15vh;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .iyzico {
        height: 3.7vh;
        margin-top: 1vh;
      }
      a {
        font-size: 1.5vh;
        color: $--footer-gray;
        font-weight: 700;
      }
      h5 {
        font-size: 1.5vh;
        font-weight: 700;
        margin-top: 1vh;
      }
      h2 {
        font-size: 1.5vh;
        font-weight: 700;

        span {
          font-weight: 400;
        }
      }

      p {
        font-size: 1.5vh;
        margin-top: 1vh;
        line-height: 1.7vh;
        max-width: 400px;
      }
    }

    .centerInfo {
      a {
        &:first-child {
          margin-bottom: 1vh;
        }

        &:nth-child(4) {
          margin-top: 1vh;
        }
      }
      h3 {
        font-size: 1.5vh;
        font-weight: 400;
        margin-top: 1vh;
        cursor: pointer;

        &:hover {
          color: $--main-red;
        }
      }
    }

    .rightInfo {
      a {
        &:nth-child(2),
        &:nth-child(3) {
          margin-bottom: 1vh;
        }

        &:hover {
          color: $--main-red;
        }
      }
      h2 {
        margin-bottom: 1vh;
      }
      a {
        font-weight: 400;
      }
    }
  }

  .leftArrow,
  .rightArrow {
    height: 15vh;

    img {
      height: 100%;
    }
  }

  .rightArrow {
    img {
      transform: rotate(180deg);
    }
  }
}

@media only screen and (max-width: 938px) {
  .footer {
    text-align: center;
    .rightArrow {
      width: 90%;
      height: unset;
      img {
        width: 100%;
      }
    }
    .wrapper {
      flex-direction: column;
      align-items: center;
      border: 1px dashed $--main-red;
      width: 77%;

      .center {
        flex-direction: column;
        align-items: center;
        gap: 25px;

        .info {
          align-items: center;
          h2 {
            color: $--main-red;
            text-align: center;

            span {
              color: #70777e;
            }
          }
        }

        .rightInfo {
          gap: 10px;
          h2 {
            color: #70777e;
          }
        }

        .seperator {
          width: 90%;
          height: unset;
        }
      }
    }
  }
}
