@import '../../assets/style/variables.module.scss';

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .logo {
    height: 8.5vh;
    img {
      height: 100%;
    }
  }
  .buttonWrapper {
    display: flex;
    gap: 4vh;

    .shop {
      font-size: 2.7vh;
      color: $--main-red;
      border: 2px solid $--main-red;
      padding: 2.7vh;
      box-sizing: border-box;
      text-align: left;
      font-weight: 500;
      transition: all 0.4s ease;
      position: relative;
      letter-spacing: 0.2vh;
      line-height: 3.1vh;

      &:hover {
        .circle {
          display: flex;
        }
      }

      .circle {
        width: 25px;
        height: 25px;
        background: $--main-red;
        border-radius: 50%;
        position: absolute;
        display: none;

        &:nth-child(2) {
          right: 10px;
          top: -12.5px;
        }

        &:nth-child(3) {
          right: 40%;
          bottom: -12.5px;
        }

        &:nth-child(4) {
          left: 20%;
          bottom: -50px;
        }

        &:nth-child(5) {
          left: -12.5px;
          top: -12.5px;
        }
      }
    }

    .about {
      font-size: 2.7vh;
      color: $--main-red;
      border: 2px solid $--main-red;
      padding: 2.7vh;
      box-sizing: border-box;
      text-align: left;
      font-weight: 500;
      transition: all 0.4s ease;
      position: relative;
      letter-spacing: 0.2vh;
      line-height: 3.1vh;

      &:hover {
        .circle {
          display: flex;
        }
      }

      .circle {
        width: 25px;
        height: 25px;
        background: $--main-red;
        border-radius: 50%;
        position: absolute;
        display: none;

        &:nth-child(2) {
          left: 10px;
          top: -12.5px;
        }

        &:nth-child(3) {
          right: 25%;
          bottom: -12.5px;
        }

        &:nth-child(4) {
          right: 20%;
          top: -50px;
        }

        &:nth-child(5) {
          left: -12.5px;
          bottom: -12.5px;
        }
        &:nth-child(6) {
          top: 10px;
          right: 10px;
        }
      }
    }

    .active {
      .circle {
        display: flex;
      }
    }

    button {
      border: 2px solid $--main-red;
      border-radius: 50%;
      width: 11.6vh;
      position: relative;

      &:hover {
        .circle {
          display: flex;
        }
      }

      h5 {
        color: $--main-red;
        font-size: 2.4vh;
        transform: rotate(-45deg);
        letter-spacing: 0.08em;
        font-weight: 600;
      }

      h6 {
        position: absolute;
        background: $--main-red;
        color: white;
        width: 25px;
        height: 25px;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 1.8vh;
        font-weight: 500;
      }

      .circle {
        width: 25px;
        height: 25px;
        background: $--main-red;
        border-radius: 50%;
        position: absolute;
        display: none;

        &:nth-child(3) {
          right: 40%;
          bottom: -12.5px;
        }

        &:nth-child(4) {
          right: 70%;
          top: 0px;
        }

        &:nth-child(5) {
          right: -6.5px;
          top: -30.5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 938px) {
  .header {
    flex-direction: column;
    width: 85%;
    align-items: center;
    .logo {
      height: 5vh;
      margin-bottom: 20px;
    }

    .buttonWrapper {
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      .shop,
      .about {
        font-size: 12px;
        color: $--main-red;
        border: 2px solid $--main-red;
        padding: 10px;
        box-sizing: border-box;
        text-align: left;
        font-weight: 500;
        transition: all 0.4s ease;
        position: relative;
        letter-spacing: 0.05em;
        line-height: 16px;
        text-align: center;

        .circle {
          height: 10px;
          width: 10px;

          &:nth-child(2) {
            right: 20px;
            top: -6.5px;
          }

          &:nth-child(3) {
            right: 40%;
            bottom: -6.5px;
          }

          &:nth-child(4) {
            left: 20%;
            bottom: -15px;
          }

          &:nth-child(5) {
            left: -12.5px;
            top: -12.5px;
          }
        }
      }

      button {
        min-height: 55px;
        min-width: 55px;
        width: 55px;
        margin-left: 20px;
        .circle {
          height: 10px;
          width: 10px;
        }

        h5 {
          font-size: 12px;
        }

        h6 {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
