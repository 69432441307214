@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-ExtraBold.woff2') format('woff2'),
    url('../fonts/ChivoMono-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Light.woff2') format('woff2'),
    url('../fonts/ChivoMono-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Black.woff2') format('woff2'),
    url('../fonts/ChivoMono-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Bold.woff2') format('woff2'),
    url('../fonts/ChivoMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Medium.woff2') format('woff2'),
    url('../fonts/ChivoMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Regular.woff2') format('woff2'),
    url('../fonts/ChivoMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-SemiBold.woff2') format('woff2'),
    url('../fonts/ChivoMono-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Thin.woff2') format('woff2'),
    url('../fonts/ChivoMono-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-Italic.woff2') format('woff2'),
    url('../fonts/ChivoMono-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-MediumItalic.woff2') format('woff2'),
    url('../fonts/ChivoMono-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Chivo Mono';
  src: url('../fonts/ChivoMono-LightItalic.woff2') format('woff2'),
    url('../fonts/ChivoMono-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

$--main-red: #e6141e;

body,
button,
input,
textarea,
select {
  font-family: 'Chivo Mono', sans-serif;
}

button {
  cursor: pointer;
  background: transparent;
}
